body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdw-editor-toolbar {
  display: none !important;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}